/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-table-link {
  @apply font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline;
}

.custom-button {
  /* @apply p-1.5 uppercase tracking-wide bg-orange-500 rounded-lg shadow-lg hover:text-me; */
  @apply
    bg-custom-button-main
    hover:shadow-lg
    hover:shadow-custom-button-ring
    border
    border-transparent
    active:ring-2
    active:ring-custom-button-hover
    text-white
    font-bold
    py-2
    px-4
    rounded    
    box-border;
    /* box-shadow: 0 0 5px green; */
}

.custom-button-sm {
  /* @apply p-1.5 uppercase tracking-wide bg-orange-500 rounded-lg shadow-lg hover:text-me; */
  @apply
    bg-custom-button-main_small
    hover:shadow-lg
    hover:shadow-custom-button-ring
    border-gray-600
    border
    active:ring-2
    active:ring-custom-button-hover
    text-gray-600
    font-bold
    py-1
    px-2
    rounded    
    box-border
    text-xs;
    /* box-shadow: 0 0 5px green; */
}

.custom-button-testing {
  @apply
    text-white
    bg-blue-700
    hover:bg-blue-800
    active:ring-4
    active:ring-blue-300
    font-medium
    rounded-lg
    text-sm
    px-5
    py-2.5
    mr-2
    mb-2;
}

h2 {
  @apply
    text-2xl
    font-medium
    text-custom-table-header
}